<template>
  <div class="home">
    <div class="home__title">GET CRACK'N</div>
    <div class="joke">
      <div class="joke__box1" id="box1"> {{ setup }}</div>
      <div class="joke__box2" id="box2"> {{ delivery }}</div>
    </div>

    <div class="joke">
      <button class="joke__actions" @click="fetchJokes">Again</button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
export default {
  name: "Home",
  components: {

  },
  data() {
    return {
      setup: '',
      delivery: ''
    }
  },
  created() {
    this.fetchJokes();
  },
  methods:{
    async fetchJokes() {
      await axios.get('https://v2.jokeapi.dev/joke/Any?type=twopart').then( res => {
        const response = res.data
        this.setup = response.setup
        this.delivery = response.delivery
      })
      const box1 = document.getElementById('box1')
      const box2 = document.getElementById('box2')

      if(!box1.classList.contains('fadein-first') && !box2.classList.contains('fadein-second') ) {
        box1.classList.add('fadein-first');
        box2.classList.add('fadein-second');

        setTimeout( () => {
          box1.classList.remove('fadein-first');
          box2.classList.remove('fadein-second');
        }, 1000)
      }


    }
  }
};
</script>

<style lang="scss" scoped>
.home{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to left bottom, #140e30, #181236, #1c163b, #211a41, #251e47);

  &__title {
    font-size: 72px;
    color: whitesmoke;
    text-align: center;
    margin-bottom: 150px;
  }

}

.joke  {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__box1 {
    padding: 30px 60px;
    text-align: center;
    border: 1px solid whitesmoke;
    margin: 20px;
    font-size: 36px;
    border-radius: 16px;
    color: whitesmoke;
  }

  &__box2 {
    padding: 30px 60px;
    text-align: center;
    border: 1px solid whitesmoke;
    margin: 20px;
    font-size: 36px;
    border-radius: 16px;
    color: whitesmoke;
  }

  &__actions {
    padding: 10px 30px;
    width: 200px;
    height: 80px;
    border-radius: 16px;
    background-color: #3cbdc2;
    color: whitesmoke;
    margin: 40px;
    text-align: center;
    font-size: 24px;
    cursor: pointer;
    border: none;
    outline: none;
  }
}

.fadein-first{
  animation: fadein 1s;

}
.fadein-second{
  animation: fadein 3s;

}
@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

</style>